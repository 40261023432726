<template>
  <div>河源市振安实验高级中学官网管理系统</div>
</template>

<script>
export default {
  name: 'Welcome'
}
</script>

<style scoped>

</style>
