<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box"><!--头像-->
        <img src="../assets/hy_logo.png">
      </div>
      <!--登录表单区域-->
      <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form">
        <!--用户名-->
        <el-form-item prop="userName">
          <el-input v-model="loginForm.userName" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <!--按钮区域-->
        <el-form-item>
          <div >
<!--            <span class="reset-pwd" @click="toResetPwd()">忘记密码？</span>-->
            <div class="btns">
              <el-button type="primary" @click="login" :loading="loading">登录</el-button>
              <el-button type="info" @click="resetLoginForm">重置</el-button>
            </div>
          </div>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      // 这是登录表单的数据绑定对象
      loginForm: {
        userName: '',
        password: ''
      },
      // 表单校验
      loginFormRules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      }
    }
  },
  mounted(){
    localStorage.clear();
  },
  methods: {
    //进入重置密码界面
    toResetPwd() {
      this.$router.push('/resetPwd')
    },
    // 重置表单，resetFields()后面不能加冒号，会报错
    resetLoginForm: function () {
      this.$refs.loginFormRef.resetFields()
    },
    login: function () {
      this.$refs.loginFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          let { data: res } = await this.$http({
            url: '/user/login',
            data: this.loginForm,
            method:'post'
          });
          this.loading = false;
          //const res = await this.$http.post('/user/login', this.loginForm)
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            /*this.$message.success('登录成功')*/
            // 登录成功把token保存到localStorage
            window.localStorage.setItem('token', res.result)
            // 编程式导航跳转到后台主页，路由地址是/home
            await this.getUserInfo()
          }
        } else {
          //验证不通过
        }
      })
    },
    //获取登录用户信息，并跳转
    async getUserInfo() {
      const { data: res } = await this.$http.get('/user/info')
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        window.localStorage.setItem('userInfo', JSON.stringify(res.result))
        await this.$router.push('/home')
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .login_container{
    background-color:#2b4b6b;
    height:100%;
  }
  .login_box{
    width:450px;
    height:300px;
    background-color:#fff;
    border-radius: 3px;
    position: absolute;/*要先设置绝对定位设left, top才有效果*/
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);/*移自身的设置横轴上左移50%，上移50%*/
  }
  .avatar_box{
    width:70px;
    height:70px;
    border:1px solid #eee;//边框
    border-radius: 50%;//圆角
    padding:10px;//让图片往里面10
    box-shadow: 0 0 10px #eee;//向外设置10像素阴影
    position: absolute;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img{/*图片充满父盒子*/
      /*width:100%;
      height:100%;*/
      width:56px;
      height:69px;
      /*border-radius: 50%;*///圆角
     /* text-align: center;*/
      margin-left: 50%;
      /*background-color: #eee;*/
      transform: translateX(-50%);
    }
  }
  .btns{
    display: flex;//弹性布局
    justify-content: flex-end;//设置为盒子尾部对齐
  }
  .reset-pwd {
    color: rgb(118 121 127);
    cursor:pointer;
  }
  .login_form{
    position:absolute;
    bottom:0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;//规定两个并排的带边框的框，让超出的文本框往里靠
  }

</style>
<style>
.el-input__icon {
  font-size: 18px;
}
</style>
