<template>
  <el-container class="home-container">
    <!--头部区域：布局见：https://element.eleme.cn/#/zh-CN/component/container，要现在element.js里注册组件才能使用-->
    <el-header>
      <div style="height: 60px;">
        <img src="../assets/hy_logo.png" alt="" style="height: 100%;margin-left: 10px;"/>
        <span>河源市振安实验高级中学官网管理系统</span>
      </div>
<!--      <el-button type="info" @click="logout">退出</el-button>-->
      <div class="edu-user">
        <el-popover placement="bottom" trigger="hover" :visible-arrow="false">
          <div class="edu-user-list">
            <li @click="toUpdatePwd()"><i class="el-icon-edit"></i>修改密码</li>
            <li @click="logout()"><i class="el-icon-switch-button"></i>退出账号</li>
          </div>
          <div class="userName" slot="reference">
            <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
            <p class="edu-user-name">{{userInfo.fullName}}</p>
          </div>
        </el-popover>
      </div>
    </el-header>
    <!--页面主体区域-->
    <el-container>
      <!--左侧区域-->
      <el-aside :width="isCollapse ? '64px' : '200px' "><!--和控制是否收缩一起使用，展开200, 收缩64-->
        <el-menu
           background-color="#333744" text-color="#fff" active-text-color="#409EFF"
          unique-opened :collapse="isCollapse" :collapse-transition="false" router :default-active="isActivePath">
          <!--unique-opened只开启展开一个，:collapse="isCollapse"控制是否收缩，:collapse-transition="false"去掉动画，要加：,不然true只是文本-->
          <div class="toggle-button" @click="toggleColleapse">
            <i v-if="isCollapse" class="el-icon-d-arrow-right size"></i>
            <i v-else class="el-icon-d-arrow-left size"></i>
          </div><!--展开关闭菜单的按钮-->
          <el-submenu :index="item.id+''" v-for="(item, index) in meunlist" :key="item.id">
            <template slot="title">
              <i :class="iconsObj[index]" style="margin-right: 12px;"></i>
              <span>{{ item.moduleName }}</span>
            </template>
            <el-menu-item :index="subItem.url" v-for="subItem in item.childModule" :key="subItem.id" @click="saveNativePath(subItem.url)">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>{{ subItem.moduleName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧内容区域-->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      // 左侧菜单数据
      meunlist: [],
      // 一级分类图标对象
      iconsObj: {
        0: 'el-icon-s-custom',
        1: 'el-icon-s-home',
        2: 'el-icon-school',
        3: 'el-icon-document',
        4: 'el-icon-s-help',
        5: 'el-icon-s-platform',
        6: 'el-icon-office-building',
        7: 'el-icon-s-marketing',
        8: 'el-icon-s-management',
        9: 'el-icon-phone',
        10: 'el-icon-s-claim'
      },
      // 是否展开左侧面板
      isCollapse: false,
      // 当前菜单选中状态，对应上面的:default-active
      isActivePath: '',
      userInfo: ''
    }
  },
  name: 'Home',
  created () {
    let userInfo = window.localStorage.getItem('userInfo') //登录用户信息
    this.userInfo = JSON.parse(userInfo);
    this.isActivePath = window.localStorage.getItem('activePath')// 页面加载时取上次选中的菜单
    this.meunlist = this.userInfo.modules
  },
  methods: {
    logout: function () { // 退出操作，清空token,调回登录页
      window.localStorage.clear()
      this.$router.push('/login')
    },
    toggleColleapse: function () {
      this.isCollapse = !this.isCollapse
    },
    saveNativePath: function (path) { // 点击二级菜单传路径赋值为当前激活菜单，并保存到sessionStorange中，刷新后还是展开这个菜单
      this.isActivePath = path
      window.localStorage.setItem('activePath', path)
    },
    toUpdatePwd() {
      this.$router.push('/password')
      this.saveNativePath('/password')
    }
  }
}
</script>

<style lang="less" scoped>
.el-header {
  background-color: #373441;
  display: flex; /*左右布局最简单用fiex布局*/
  justify-content: space-between; /*左右贴边布局，让图标和按钮在左右两侧*/
  padding-left: 0; /*让头像左侧贴着左边*/
  color: #fff;
  align-items: center; /*让按钮上下贴边变居中*/
  font-size: 20px;

  > div { /* 让文字和头像居中*/
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.home-container {
  height: 100%;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2em; /*文字间距*/
}
.edu-user{
  padding: 0 10px;
  margin-right:8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.edu-user img{
  width: 36px;
  height:36px;
  margin-right: 8px;
  background-color:#ececec;
  border-radius: 50%;
  object-fit:cover;
}
.edu-user .userName{display:flex;align-items:center;padding:2px 0;}
.edu-term-list{
  max-height:240px;
  overflow-y:auto;
}
.edu-user-list>li{
  list-style:none;
  line-height:40px;
  color:#595959;
  padding:0 9px;
  font-size:14px;
  cursor:pointer;
}
.edu-user-list>li>i{margin-right:8px;color:#262626;font-size:16px;}
.edu-user-list>li:hover{
  background:#F5F7FA;
}
.edu-user-name {
  font-size: 16px;
}
.el-submenu__icon-arrow {
  margin-top: -27px;
}
.size {
  font-size: 18px;margin-top: 5px;
}
</style>
